import { useField } from 'formik';

import { FormControl } from '@@/form-components/FormControl';
import { Select } from '@@/form-components/Input';

import { FormValues } from '../CreateView/types';

export const periodOptions = [
  {
    label: '10 minutes',
    value: 10 * 60,
  },
  {
    label: '1 hour',
    value: 60 * 60,
  },
  {
    label: '1 day',
    value: 24 * 60 * 60,
  },
  {
    label: '1 week',
    value: 7 * 24 * 60 * 60,
  },
] as const;

export const defaultPeriodValue = periodOptions[0].value;

export function RetryDeployPeriod() {
  const [{ value, onChange, name, onBlur }, { error }] =
    useField<FormValues['retryPeriod']>('retryPeriod');

  return (
    <FormControl
      label="Retry for"
      inputId="edge_stack_retry_period"
      errors={error}
    >
      <Select
        id="edge_stack_retry_period"
        data-cy="edge-stack-retry-period-select"
        name={name}
        options={periodOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormControl>
  );
}
