export const ConfigurationOwnerUsernameLabel =
  'io.portainer.kubernetes.configuration.owner';
// the configurationOwnerUsernameLabel is already used for displaying the username in the UI, so add a new label for the id (to check ownership)
export const ConfigurationOwnerIdLabel =
  'io.portainer.kubernetes.configuration.owner.id';

export const PortainerNamespaceAccessesConfigMap = {
  namespace: 'portainer',
  configMapName: 'portainer-config',
  accessKey: 'NamespaceAccessPolicies',
};
