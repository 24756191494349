import { UpdateNamespaceAccessesPayload } from './useUpdateNamespaceAccessesMutation';
import { NamespaceAccess } from './types';

/**
 * createAccessesPayload creates the payload for the update namespace accesses mutation, using an array of user and team ids to add or remove.
 */
export function createAccessesPayload(
  selectedItemsToAdd: NamespaceAccess[],
  selectedItemsToRemove: NamespaceAccess[]
): UpdateNamespaceAccessesPayload {
  const payload: UpdateNamespaceAccessesPayload = {
    UsersToAdd: [],
    TeamsToAdd: [],
    UsersToRemove: [],
    TeamsToRemove: [],
  };

  selectedItemsToAdd.forEach((item) => {
    if (item.type === 'user') {
      payload.UsersToAdd.push(item.id);
    } else {
      payload.TeamsToAdd.push(item.id);
    }
  });

  selectedItemsToRemove.forEach((item) => {
    if (item.type === 'user') {
      payload.UsersToRemove.push(item.id);
    } else {
      payload.TeamsToRemove.push(item.id);
    }
  });

  return payload;
}
