import { useMutation } from '@tanstack/react-query';

import { queryClient, withGlobalError } from '@/react-tools/react-query';
import axios, { parseAxiosError } from '@/portainer/services/axios';
import { EnvironmentId } from '@/react/portainer/environments/types';

import type { AppKind } from '../types';

import { queryKeys } from './query-keys';

export function useRolloutRestartApplicationMutation(
  environmentId: number,
  namespace: string,
  name: string
) {
  return useMutation(
    ({ kind }: { kind: AppKind }) =>
      rolloutRestartApplication(environmentId, namespace, kind, name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.application(environmentId, namespace, name)
        );
      },
      ...withGlobalError('Unable to rollout restart application'),
    }
  );
}

// rolloutRestartApplication uses an internal api endpoint rather than the kubernetes proxy
async function rolloutRestartApplication(
  environmentId: EnvironmentId,
  namespace: string,
  kind: string,
  name: string
) {
  try {
    await axios.post(
      `/kubernetes/${environmentId}/namespaces/${namespace}/applications/${kind}/${name}/restart`
    );
  } catch (error) {
    throw parseAxiosError(
      error as Error,
      `Failed to restart application ${kind}/${name} in namespace ${namespace}`
    );
  }
}
