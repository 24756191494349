import _ from 'lodash-es';
import { KubernetesConfigMap, KubernetesPortainerAccessConfigMap } from 'Kubernetes/models/config-map/models';
import { KubernetesConfigMapCreatePayload, KubernetesConfigMapUpdatePayload } from 'Kubernetes/models/config-map/payloads';
import { KubernetesConfigurationFormValuesEntry } from 'Kubernetes/models/configuration/formvalues';
import { prepareAnnotations } from '@/react/kubernetes/utils';
import { ConfigurationOwnerUsernameLabel } from '@/react/kubernetes/configs/constants';
import KubernetesAnnotationsUtils from './annotations';

class KubernetesConfigMapConverter {
  static apiToPortainerAccessConfigMap(data) {
    const res = new KubernetesPortainerAccessConfigMap();
    res.Id = data.metadata.uid;
    res.Data = data.data;
    return res;
  }

  static createAccessPayload(data) {
    const res = new KubernetesConfigMapCreatePayload();
    _.unset(res, 'binaryData');
    res.metadata.name = data.Name;
    res.metadata.namespace = data.Namespace;
    res.data = data.Data;
    return res;
  }

  static updateAccessPayload(data) {
    const res = KubernetesConfigMapConverter.createAccessPayload(data);
    res.metadata.uid = data.Id;
    return res;
  }

  /**
   * API ConfigMap to front ConfigMap
   */
  static apiToConfigMap(data, yaml) {
    const res = new KubernetesConfigMap();
    res.Id = data.metadata.uid;
    res.Name = data.metadata.name;
    res.Namespace = data.metadata.namespace;
    res.ConfigurationOwner = data.metadata.labels ? data.metadata.labels[ConfigurationOwnerUsernameLabel] : '';
    res.CreationDate = data.metadata.creationTimestamp;
    res.Yaml = yaml ? yaml.data : '';
    res.Labels = data.metadata.labels;

    res.Data = _.concat(
      _.map(data.data, (value, key) => {
        const entry = new KubernetesConfigurationFormValuesEntry();
        entry.Key = key;
        entry.Value = value;
        return entry;
      }),
      _.map(data.binaryData, (value, key) => {
        const entry = new KubernetesConfigurationFormValuesEntry();
        entry.Key = key;
        entry.Value = value;
        entry.IsBinary = true;
        return entry;
      })
    );

    res.Annotations = data.metadata.annotations ? KubernetesAnnotationsUtils.apiToFormValueAnnotations(data.metadata.annotations) : [];

    return res;
  }

  /**
   * Generate a default ConfigMap Model
   * with ID = 0 (showing it's a default)
   * but setting his Namespace and Name
   */
  static defaultConfigMap(namespace, name) {
    const res = new KubernetesConfigMap();
    res.Name = name;
    res.Namespace = namespace;
    return res;
  }

  /**
   * CREATE payload
   */
  static createPayload(data) {
    const res = new KubernetesConfigMapCreatePayload();
    res.metadata.name = data.Name;
    res.metadata.namespace = data.Namespace.Name;
    const configurationOwner = _.truncate(data.ConfigurationOwner, { length: 63, omission: '' });
    res.metadata.labels[ConfigurationOwnerUsernameLabel] = configurationOwner;

    _.forEach(data.Data, (entry) => {
      if (entry.IsBinary) {
        res.binaryData[entry.Key] = entry.Value;
      } else {
        res.data[entry.Key] = entry.Value;
      }
    });

    res.metadata.annotations = prepareAnnotations(data.Annotations);

    return res;
  }

  /**
   * UPDATE payload
   */
  static updatePayload(data) {
    const res = new KubernetesConfigMapUpdatePayload();
    res.metadata.uid = data.Id;
    res.metadata.name = data.Name;
    res.metadata.namespace = data.Namespace;
    res.metadata.labels = data.Labels || {};
    res.metadata.labels[ConfigurationOwnerUsernameLabel] = data.ConfigurationOwner;
    _.forEach(data.Data, (entry) => {
      if (entry.IsBinary) {
        res.binaryData[entry.Key] = entry.Value;
      } else {
        res.data[entry.Key] = entry.Value;
      }
    });
    res.metadata.annotations = prepareAnnotations(data.Annotations);
    return res;
  }

  static configurationFormValuesToConfigMap(formValues) {
    const res = new KubernetesConfigMap();
    res.Id = formValues.Id;
    res.Name = formValues.Name;
    res.Namespace = formValues.ResourcePool;
    res.ConfigurationOwner = formValues.ConfigurationOwner;
    res.Data = formValues.Data;
    res.Annotations = formValues.Annotations;
    return res;
  }
}

export default KubernetesConfigMapConverter;
