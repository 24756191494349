import { useMutation } from '@tanstack/react-query';

import axios from '@/portainer/services/axios';
import { EnvironmentId } from '@/react/portainer/environments/types';

export type UpdateNamespaceAccessesPayload = {
  UsersToAdd: number[];
  TeamsToAdd: number[];
  UsersToRemove: number[];
  TeamsToRemove: number[];
};

export function useUpdateNamespaceAccessesMutation(
  environmentId: EnvironmentId,
  namespaceName: string
) {
  return useMutation({
    mutationFn: (payload: UpdateNamespaceAccessesPayload) =>
      updateNamespaceAccesses(environmentId, namespaceName, payload),
  });
}

async function updateNamespaceAccesses(
  environmentId: EnvironmentId,
  namespaceName: string,
  payload: UpdateNamespaceAccessesPayload
) {
  return axios.put(
    `/endpoints/${environmentId}/pools/${namespaceName}/access`,
    payload
  );
}
